import styled from 'styled-components';
import MEDIA_QUERY from '../../constants/mediaQuery';
import { Helmet } from 'react-helmet-async';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import client from '../../api/axios';
import { PortfolioDataType, ResultsType } from '../admin/Portfolio/babyFamily';
import { useEffect, useState } from 'react';
// import image popup
import MainPopup from '../../components/common/MainPopup/mainPopup';


const HomePage = () => {
  // localStorage.clear(); // 메인으로 전환되면 어드민 관련 정보값을 모두 지우기 위함
    const admin = localStorage.getItem('useradmin');
    if(!admin){
      localStorage.setItem('Token', '71c1e61404bcf64bc69306e0e6eeb60cd47ae766');
    }else{
      localStorage.setItem('Token', admin);
    }

  // 전체 Home 슬라이드 데이터
  const [homeSlideImageData, setHomeSlideImageData] = useState<ResultsType | null>(null);

  // 슬라이드 이미지 불러오기
  const handleQueryHomeImages = async () => {
    try {
      const response = await client.get('api/v1/portfolios/portfolio/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token 71c1e61404bcf64bc69306e0e6eeb60cd47ae766`,
        },
        withCredentials: true,
      });

      let resultsWithMCategories: ResultsType = {};

      Object.keys(response.data.results2).forEach((key: string) => {
        let filteredResults = response.data.results2[key].filter((item: PortfolioDataType) => item.portfolio_id === 1); // 카테고리마다 portfolio_id가 다름
        if (filteredResults.length > 0) {
          resultsWithMCategories[key] = filteredResults;
        }
      });

      setHomeSlideImageData(resultsWithMCategories);
    } catch (error) {
      console.error(error);
    }
  };

  // 페이지 진입 시 데이터 불러오기
  useEffect(() => {
    handleQueryHomeImages();
  }, []);

  return (
    <>
      <Helmet>
        <title>눈부신 일상</title>
      </Helmet>
      <Containter>
        <MainImageWrapper>
          {homeSlideImageData && homeSlideImageData[1] ? (
            <SwiperWrapper
              centeredSlides={true}
              modules={[Autoplay, EffectFade]}
              spaceBetween={50}
              slidesPerView={1}
              speed={2000}
              autoplay={{
                delay: 1000,
              }}
              effect={'fade'}
              fadeEffect={{ crossFade: true }}
            >
              {homeSlideImageData[1].map((item: PortfolioDataType) => (
                <SwiperSlide key={item.id}>
                  <MainImage src={item.img_url} alt="홈페이지 이미지" />
                </SwiperSlide>
              ))}
            </SwiperWrapper>
          ) : (
            <p>슬라이드 이미지가 없습니다.</p>
          )}
        </MainImageWrapper>
      </Containter>


    </>
  );
};

export default HomePage;

const Containter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const SwiperWrapper = styled(Swiper)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${MEDIA_QUERY.lg} {
    width: 69rem;
    height: 43rem;
  }

  ${MEDIA_QUERY.md} {
    width: 69rem;
    height: 43rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 33.4rem;
    height: 20.8rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
    height: 15.6rem;
  }
`;

const MainImage = styled.img`
  ${MEDIA_QUERY.lg} {
    width: 69rem;
    height: 43rem;
  }

  ${MEDIA_QUERY.md} {
    width: 69rem;
    height: 43rem;
  }

  ${MEDIA_QUERY.sm} {
    width: 33.4rem;
    height: 20.8rem;
  }

  ${MEDIA_QUERY.xs} {
    width: 25rem;
    height: 15.6rem;
  }
`;

